import * as React from "react"
import { graphql } from 'gatsby';

import Layout from "../components/layout"
import Seo from "../components/seo"

// The bridge for Gatsby Background Image in V3
import { BgImage } from 'gbimage-bridge'

// All the gatsby-plugin-image goodness
import { StaticImage, getImage } from "gatsby-plugin-image"

import { Container, Carousel } from "react-bootstrap"

const SecondPage = ({data}) => (
  <Layout>
    <BgImage 
      image={getImage(data.indexImage)} 
      className="masthead"
    >
       <div className="black-overlay">
            <div className="content-box">
              <h1>The Wick</h1>
            </div>
          </div>
    </BgImage>

    <Container>
      <p className="summary">
      The Wick is located in the historic Royal Hotel located on Newcastle Street in downtown Brunswick, Georgia. Built in 1908, this property has gone through multiple modernizations over the course of its lifetime, but still maintains its original historic charm. With high ceilings adorned with pressed tin, gorgeous hardwood floors, original skylights, and large windows throughout, our office suites are truly one of a kind. Along with historic charm this working community also houses a 14 person executive boardroom, kitchen, and copy center. Offering over 30 private office suites ranging from 100 - 300 square feet this community of entrepreneurs is an essential part of Brunswick’s vibrant small business network.
      </p>
    </Container>

    <Container className="singleCol">
      <Carousel autoPlay interval={80000} touch={true}>
        <Carousel.Item>
        <StaticImage
          src="../images/the-wick/wick01.jpg"
          quality={95}
          formats={["auto", "webp", "avif"]}
          alt="The Wick"
          className="d-block img-fluid"
        />
        </Carousel.Item>
        <Carousel.Item>
          <StaticImage
            src="../images/the-wick/wick02.jpg"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="The Wick"
            className="d-block img-fluid"
          />
        </Carousel.Item>
        <Carousel.Item>
          <StaticImage
            src="../images/the-wick/wick03.jpg"
            quality={95}
            formats={["auto", "webp", "avif"]}
            alt="The Wick"
            className="d-block img-fluid"
          />
        </Carousel.Item>
      </Carousel>
    </Container>

    <div className="subhead">
    <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d853.6267005218305!2d-81.496362070799!3d31.15120889884333!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x88e4d817bb4f97c1%3A0x7567711ebf771008!2s1612%20Newcastle%20St%2C%20Brunswick%2C%20GA%2031520!5e0!3m2!1sen!2sus!4v1574797196651!5m2!1sen!2sus" width="100%" height="100%" title="helo" frameborder="0"></iframe>
    </div>

  </Layout>
)

export const Head = () => <Seo title="The Wick" />

export default SecondPage

export const query = graphql`
  query {
    indexImage: file(relativePath: {eq: "wick1-header.jpg"}) {
      id
      base
      relativePath
      childImageSharp {
        gatsbyImageData(
          width: 2000
          webpOptions: {quality: 30}
          placeholder: BLURRED
          blurredOptions: {width: 30}
        )
      }
    }
  }
`